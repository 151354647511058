$(document).ready(function(){

    mapInit([], [], 'objects-map-init'); //RECardDescription-Map
    mapInit([], [], 'land-plots-map'); //RECardDescription-Map
    mapInit([54.718005, 55.954909], [54.718005, 55.954909], 'contact-map'); //RECardDescription-Map


    validateForm({
        formId: 'form-intro'
        //formValidClass: 'Form_valid',
        //formInvalidClass: 'Form_invalid',
        //inputErrorClass: 'Input_error'
    });
    validateForm({
        formId: 'form-slider'
        //formValidClass: 'Form_valid',
        //formInvalidClass: 'Form_invalid',
        //inputErrorClass: 'Input_error'
    });
    validateForm({
        formId: 'form-live'
        //formValidClass: 'Form_valid',
        //formInvalidClass: 'Form_invalid',
        //inputErrorClass: 'Input_error'
    });
    validateForm({
        formId: 'form-mortgage'
        //formValidClass: 'Form_valid',
        //formInvalidClass: 'Form_invalid',
        //inputErrorClass: 'Input_error'
    });

    $('.FormSlider').slick({
        slidesToShow: 1,
        slidesToScroll: 1,
        infinite: false,
        arrows: true,
        dots: false,
        adaptiveHeight: true,
        prevArrow: '',
        nextArrow: '<button type="button" class="FormSlider-Arrow FormSlider-Next Btn">' +
        'Следующий вопрос <svg width="9" height="14">\n' +
        '    <use\n' +
        '        xlink:href="/img/sprite.svg#arrow-all-project"\n' +
        '        href="/img/sprite.svg#arrow-all-project"></use>\n' +
        '</svg>' +
        '</button>'
    });

    $('.AdvantagesCardSlider').slick({
        slidesToShow: 1,
        slidesToScroll: 1,
        infinite: true,
        arrows: true,
        dots: false,
        centerMode: true,
        prevArrow: '<button type="button"\n' +
'                                            class="Slider-Arrow Slider-Arrow-Prev">\n' +
'                                        <svg width="9" height="14">\n' +
'                                            <use\n' +
'                                                xlink:href="/img/sprite.svg#arrow-slider"\n' +
'                                                href="/img/sprite.svg#arrow-slider"></use>\n' +
'                                        </svg>\n' +
'                                    </button>',
        nextArrow: '<button type="button"\n' +
'                                            class="Slider-Arrow Slider-Arrow-Next">\n' +
'                                        <svg width="9" height="14">\n' +
'                                            <use\n' +
'                                                    xlink:href="/img/sprite.svg#arrow-slider"\n' +
'                                                    href="/img/sprite.svg#arrow-slider"></use>\n' +
'                                        </svg>\n' +
'                                    </button>'
    });
});